import * as React from 'react'
import { Mousewheel } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
// import { useWindowSize } from 'usehooks-ts'
import { useWindowSize } from '#app/hooks/useWindowSize.js'
import { type SliderCard as SliderCardType } from '../../types/graphql.js'
import SliderCard from './sliderCard.tsx'

type SliderProps = {
	cards: SliderCardType[]
}
export default function Slider({ cards }: SliderProps) {
	const [numOfSlides, setNumOfSlides] = React.useState(3)
	const { width } = useWindowSize()
	// const width = 1280

	React.useEffect(() => {
		let leftMargin = 0
		if (width >= 1280) {
			leftMargin = (width - 1280) / 2
		} else if (width >= 1024) {
			leftMargin = (width - 1024) / 2
		} else if (width >= 767) {
			leftMargin = (width - 767) / 2
		} else if (width >= 640) {
			leftMargin = (width - 600) / 2
		}
		console.log((width - leftMargin) / 400, width)
		setNumOfSlides((width - leftMargin) / 400 || 1)
	}, [width])

	return (
		<Swiper
			simulateTouch={true}
			freeMode={true}
			slidesPerView={numOfSlides}
			spaceBetween={40}
			mousewheel={{
				forceToAxis: true,
			}}
			pagination={{
				enabled: false,
			}}
			modules={[Mousewheel]}
			className="mySwiper"
			onDrag={() => console.log('draging')}
		>
			{cards.map((card) => (
				<SwiperSlide
					className="first:ml-0 sm:first:ml-4 min-[560px]:first:ml-8 md:first:ml-4"
					key={card.uid}
				>
					<SliderCard card={card} />
				</SwiperSlide>
			))}
			{width > 767 ? <SwiperSlide></SwiperSlide> : null}
		</Swiper>
	)
}
